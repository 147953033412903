import GD_UI_DS, {
  ICON_LIST,
  ru as langRu,
  type GlobalConfig
} from '@altai/core';
import {
  createQui,
  localeRu,
  QButton,
  QCheckbox,
  QCol,
  QCollapse,
  QCollapseItem,
  QContextMenu,
  QDatePicker,
  QForm,
  QFormItem,
  QInput,
  QInputNumber,
  QNotification,
  QOption,
  QPagination,
  QPopover,
  QProgressIndicatior,
  QRow,
  QScrollbar,
  QSelect,
  QSlider,
  QSwitch,
  QTable,
  QTabPane,
  QTabs,
  QTag,
  QTextarea
} from '@qvant/qui-max';
import type { App } from 'vue';

import '@qvant/qui-max/css/main';
import '../assets/styles/icons.scss';
import '../assets/styles/variables.scss';

import '@qvant/qui-max/css/q-button';
import '@qvant/qui-max/css/q-checkbox';
import '@qvant/qui-max/css/q-col';
import '@qvant/qui-max/css/q-collapse-item';
import '@qvant/qui-max/css/q-context-menu';
import '@qvant/qui-max/css/q-date-picker';
import '@qvant/qui-max/css/q-drawer';
import '@qvant/qui-max/css/q-form-item';
import '@qvant/qui-max/css/q-input';
import '@qvant/qui-max/css/q-input-number';
import '@qvant/qui-max/css/q-message-box';
import '@qvant/qui-max/css/q-notification';
import '@qvant/qui-max/css/q-option';
import '@qvant/qui-max/css/q-pagination';
import '@qvant/qui-max/css/q-popover';
import '@qvant/qui-max/css/q-progress-indicatior';
import '@qvant/qui-max/css/q-row';
import '@qvant/qui-max/css/q-scrollbar';
import '@qvant/qui-max/css/q-select';
import '@qvant/qui-max/css/q-slider';
import '@qvant/qui-max/css/q-switch';
import '@qvant/qui-max/css/q-tabs';
import '@qvant/qui-max/css/q-tab-pane';
import '@qvant/qui-max/css/q-table';
import '@qvant/qui-max/css/q-tag';
import '@qvant/qui-max/css/q-textarea';

import PageWrap from '@/components/PageWrap';

import '@altai/fonts';
import '@altai/theme-base/css/altai-themes';
import '@altai/theme-base';

const Qui = createQui({
  localization: {
    locale: 'ru',
    messages: { ru: localeRu }
  }
});
const globalConfig: Partial<GlobalConfig> = { locale: langRu };

const setupAppComponents = (app: App<Element>): void => {
  app.component('PageWrap', PageWrap);

  ICON_LIST.forEach(iconComponent => app.use(iconComponent));

  app.use(GD_UI_DS, globalConfig);
  app.use(Qui);

  app.use(QButton);
  app.use(QCheckbox);
  app.use(QCol);
  app.use(QCollapse);
  app.use(QCollapseItem);
  app.use(QContextMenu);
  app.use(QDatePicker);
  app.use(QForm);
  app.use(QFormItem);
  app.use(QInput);
  app.use(QInputNumber);
  app.use(QNotification);
  app.use(QOption);
  app.use(QPagination);
  app.use(QPopover);
  app.use(QProgressIndicatior, { stackable: false });
  app.use(QRow);
  app.use(QScrollbar);
  app.use(QSelect);
  app.use(QSlider);
  app.use(QSwitch);
  app.use(QTable);
  app.use(QTabs);
  app.use(QTabPane);
  app.use(QTag);
  app.use(QTextarea);
};

export { setupAppComponents };
