import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useLoadingStore = defineStore('loading', () => {
  const firstVisitPageLoaded = ref(false);

  const togglePageLoaded = (value: boolean = true): void => {
    firstVisitPageLoaded.value = value;
  };

  return {
    firstVisitPageLoaded,
    togglePageLoaded
  };
});
