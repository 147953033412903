import { convertKeysToCamel } from '@/helpers/convertKeys';
import { request } from '@/services';

import type { GetTariffArg, GetTariffResult } from './types';

export const getTariff = async ({
  signal
}: GetTariffArg): Promise<GetTariffResult> => {
  return request<GetTariffResult>({
    signal,
    path: `tariff`,
    method: 'GET',
    contentType: 'json'
  }).then(convertKeysToCamel);
};

export type { Tariff, TariffModel, TariffOptions } from './types';
