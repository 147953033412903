import type { Nullable } from '@altai/core';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { recordTrexEvent } from '@/helpers/recordTrexEvent';
import { getTariff, type Tariff, type TariffModel } from '@/services/tariff';
import { getUserByUsername } from '@/services/user';

import type { Optional } from '#/types';

import type { ProfileState } from './types';

export const useProfileStore = defineStore({
  id: 'profile',

  state: (): ProfileState => ({
    user: null,
    keycloak: null,
    userTariff: null
  }),

  getters: {
    isUserExist(): boolean {
      return Boolean(this.user);
    },

    userId(): Optional<number> {
      return this.user?.id;
    },

    isUserDetached(): boolean {
      return !this.user?.customerId;
    },

    userTariffData(): Nullable<Tariff> {
      return this.userTariff;
    },

    userPermissions(): TariffModel[] | [] {
      return this.userTariff?.models || [];
    }
  },

  actions: {
    setState({ user, keycloak, userTariff }: Partial<ProfileState>): void {
      if (keycloak) this.keycloak = keycloak;
      if (user) this.user = user;
      if (userTariff) this.userTariff = userTariff;
    },

    async getCurrentUser(username: string): Promise<void> {
      const tariffAbortController = ref<Nullable<AbortController>>(null);

      try {
        if (tariffAbortController.value) tariffAbortController.value?.abort();
        tariffAbortController.value = new AbortController();

        const user = await getUserByUsername({ params: { username } });
        const userTariff = await getTariff({
          signal: tariffAbortController.value.signal
        });
        this.setState({ user, userTariff });
      } catch (error) {
        this.logout();

        throw error;
      }
    },

    async logout(): Promise<void> {
      recordTrexEvent({ event: 'logout' });
      await this.keycloak?.logout();
      this.$reset();
    }
  }
});

export type { ProfileState };
